<template>
  <div id="app">
    <HeaderView />
    <router-view />
    
    <FooterView />
  </div>
</template>

<script>
import HeaderView from './components/layouts/HeaderView.vue'
import FooterView from './components/layouts/FooterView.vue'
import { startTokenRefresh } from './services/TokenRefresh';
import {useInactivityLogout} from './services/useInactivityLogout'

export default {
  name: 'App',
  components: {
    HeaderView,
    FooterView
  },
  created(){
    startTokenRefresh();
    useInactivityLogout(7200000);
  }
}
</script>

<style>
/* Add any global styles here */
</style>
