<template>
  <!-- Hero Section Begin -->
  <SliderComponent />
  <!--<CategorieComponent />-->
  <BestSellerComponent />
  <NewArrivalComponent />
 
  <!--<AboutComponent />-->
 
  <!-- Hero Section End -->
  <InfoComponent />
  <CertifComponent />
</template>

<script>
// @ is an alias to /src
import SliderComponent from '../components/home/SliderComponent.vue'
//import CategorieComponent from '@/components/home/CategorieComponent.vue';
import NewArrivalComponent from '@/components/home/NewArrivalComponent.vue';
//import AboutComponent from '@/components/home/AboutComponent.vue';
import InfoComponent from '@/components/home/InfoComponent.vue';
import BestSellerComponent from '@/components/home/BestSellerComponent.vue';
import CertifComponent from '@/components/home/CertifComponent.vue';

export default {
  name: 'HomeView',
  components:{
    SliderComponent,
    //CategorieComponent,
    NewArrivalComponent,
    //AboutComponent,
    InfoComponent,
    BestSellerComponent,
    CertifComponent
  }
}
</script>
<style scoped>
</style>